import React, { useState } from "react"
import api, { useRequest } from "@app/services/api"

import VendorMappingsHeader from "./components/VendorMappingsHeader"

import { useParams } from "react-router-dom"
import VendorMappingsTable from "./components/VendorMappingsTable"
import UpdateCreateMappingModal from "./components/UpdateCreateMappingsModal"

type PropsType = {
  vendorIntegration: VendorIntegration
}

export default ({ vendorIntegration }: PropsType) => {
  const { id } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [selectedMapping, setSelectedMapping] =
    useState<VendorIntegrationMapping>()

  const { data: mappings, mutate } = useRequest([
    api.getVendorIntegrationMappings,
    id,
  ])

  const filteredMappings = mappings?.filter(
    (mapping: VendorIntegrationMapping) => !!mapping.internal_id
  )
  const onHide = () => {
    setShowModal(false)
    setSelectedMapping(undefined)
  }

  return (
    <>
      <VendorMappingsHeader
        vendorIntegration={vendorIntegration}
        showModal={() => {
          setShowModal(true)
          setSelectedMapping(undefined)
        }}
      />
      <VendorMappingsTable
        mappings={filteredMappings}
        showEditModal={(mapping: VendorIntegrationMapping) => {
          setShowModal(true)
          setSelectedMapping(mapping)
        }}
        mutate={mutate}
        onHide={onHide}
      />
      <UpdateCreateMappingModal
        vendorIntegrationId={vendorIntegration ? vendorIntegration.id : ""}
        show={showModal}
        selectedMapping={selectedMapping}
        mutate={mutate}
        onHide={onHide}
      />
    </>
  )
}
